import React from "react"

const LlcSyorui = () => {
  const title = `合同会社設立手続`
  const content = `合同会社とは株式会社と違い、出資を広く集めるということはできません。株式会社は株主が出資者で、会社の経営を取締役という役員を選任して行わせ、会社の利益の分配を受けるという関係になります。このことを「所有と経営の分離」といいます。
    
  合同会社の場合は、会社法上で、社員といわれる出資者がそのまま経営を行います。このことを「所有と経営の一致」といいます。
  
  合同会社という会社形態は、日本ではまだ歴史が浅く、知名度も低かったので、これまで、１人オーナー社長の会社であっても、対外的な関係で、株式会社という会社形態が選択されてきましたが、近年では、誰しもが知る有名な合同会社の企業がでてきています。
  
  行政の許可や認可の取得に問題がなく、広く出資を集めて、近い将来、株式上場を目指すという予定がないのであれば、設立コストが低く、経営の意思決定、柔軟性の高い会社形態である合同会社の設立を検討されてみてはいかがでしょうか。
  
  以下、合同会社の設立手続きの流れを簡単にご紹介します。
    `

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．定款作成</dt>
            <dd>
              定款とは、会社の目的・組織・活動・構成員・業務執行などについての基本規則を定めたものです。
              <br />
              合同会社の場合、社員が有限責任（出資の範囲内で責任を負担する）である旨の記載が必要です。
              <br />
              また、業務を執行しない社員を決めたり、社員の中で会社を代表する社員を決めたりすることができます。
              <br />
              そして、作成した定款は、公証人の認証を受ける必要はありません。
              <br />
              ただし、作成者が電子署名をした電子定款ではなく、定款を書面で保管される場合は、４万円の収入印紙の貼付が必要となります。
            </dd>

            <dt>２．出資の引受・払込</dt>
            <dd>
              定款の作成後、社員が出資金の払込を行います。
              <br />
              登記申請の添付書類とするため、銀行口座の入金記録をコピー等しておく必要があります。
            </dd>
            <dt>３．設立登記の申請</dt>
            <dd>
              設立時役員は、以上の出資の払込、手続きの適法性を確認した後、法務局への登記申請を行います。
              <br />
              登記申請の際に登録免許税として、資本金の額の１,０００分の７（０.７％）または、その金額が６万円に満たない場合は６万円納付する必要があります。
              <br />
              この登記申請時に会社の印鑑届出を同時に行います。この印鑑が会社の実印となります。
              <br />
              なお、法改正により会社の印鑑届出が義務ではなくなる予定ですが、銀行口座の開設等、対外的に会社の実印を使うことが急になくなることはないと思われます。
            </dd>
            <dt>４．税務署等の行政への届出</dt>
            <dd>
              登記が完了すると、税務署、都道府県・市町村に法人設立届等の提出や、日本年金機構への社会保険加入手続き等もしなければなりません。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default LlcSyorui
