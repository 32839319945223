import React from "react"

const EstablishSyorui = () => {
  const title = `株式会社設立手続`
  const content = `株式会社の設立には発起設立と募集設立があります。
  募集設立は設立時の株式を、発起人（会社を作ろうとする人）以外から募集して設立するのに対し、発起設立は、設立時の株式全てを発起人が引受けて設立します。
  
  実務では、ほとんどが発起設立です。以下、発起設立の手続きの流れについて簡単にご紹介します。

`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．定款作成・認証</dt>
            <dd>
              定款とは、会社の目的・組織・活動・構成員・業務執行などについての基本規則を定めたものです。
              <br />
              会社を設立しようとする人（発起人といいます）は、定款を作成し署名捺印をしなければなりません。
              <br />
              一般的には、定款に記載しなければならない、商号、本店、目的等の他、発起人が決めるべき、設立時の役員や、設立時株式の払込に関する事項についても記載してきます。
              <br />
              そして、作成した定款は、公証人の認証を受けなければなりません。費用として、公証人の手数料５万円と、定款謄本発行通数ごとに支払う手数料等の他、４万円の収入印紙代がかかります。
              <br />
              ただし、電子定款を作成する場合、収入印紙代は不要となります。
            </dd>

            <dt>２．設立時株式の引受・払込</dt>
            <dd>
              定款の認証後、発起人が株式払込を行います。
              <br />
              登記申請の添付書類とするため、銀行口座の入金記録をコピー等しておく必要があります。
            </dd>
            <dt>３．設立登記の申請</dt>
            <dd>
              設立時役員は、以上の出資の払込、手続きの適法性を確認した後、法務局への登記申請を行います。
              <br />
              登記申請の際に登録免許税として、資本金の額の１,０００分の７（０.７％）または、１５万円に満たない場合は１５万円納付する必要があります。
              <br />
              この登記申請時に会社の印鑑届出を同時に行います。この印鑑が会社の実印となります。
              <br />
              なお、法改正により会社の印鑑届出が義務ではなくなる予定ですが、銀行口座の開設等、対外的に会社の実印を使うことが急になくなることはないと思われます。
            </dd>
            <dt>４．税務署等の行政への届出</dt>
            <dd>
              登記が完了すると、税務署、都道府県・市町村に法人設立届等の提出や、日本年金機構への社会保険加入手続き等もしなければなりません。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default EstablishSyorui
