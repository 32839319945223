import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import EstablishSyorui from "../components/establishDetail.js/establishSyorui"
import LlcSyorui from "../components/establishDetail.js/llcSyorui"
import EstablishCost from "../components/establishDetail.js/establishCost"
import BackContact from "../components/backContact"
import BlogLink from "../components/blogLink"

const Establish = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      establish: file(relativePath: { eq: "establish.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "株式会社、合同会社の設立について、定款の作成から登記申請まで、手続きの流れや費用などについて、大阪の司法書士事務所の司法書士法人みつわ合同事務所がご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="会社設立｜電子定款作成 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.establish.childImageSharp.original.src}
        pageimgw={data.establish.childImageSharp.original.width}
        pageimgh={data.establish.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"establish"}
          fluid={data.establish.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"設立登記"}
        />
        <section className="establish">
          <div className="establish__container">
            <h1 className="establish__title">会社設立</h1>
            <EstablishSyorui />
            <LlcSyorui />
            <EstablishCost />
            <BlogLink link={"establish"} linkName={"設立"} />
          </div>
        </section>
        <BackContact link={"/corp/"} linkName={"戻る"} />
      </div>
    </Layout>
  )
}

export default Establish
